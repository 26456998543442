//
// locales/glitch/ko.js
// automatically generated by generateLocalePacks.js
//

import messages from '../../../app/javascript/flavours/glitch/locales/ko';
import localeData from 'react-intl/locale-data/ko.js';
import { setLocale } from 'locales';

setLocale({
  localeData,
  messages,
});
