import inherited from 'mastodon/locales/ko.json';

const messages = {
  'account.add_account_note': '@{name} 님에 대한 메모 추가',
  'account.disclaimer_full': '아래에 있는 정보들은 사용자의 프로필을 완벽하게 나타내지 못하고 있을 수도 있습니다.',
  'account.follows': '팔로우',
  'account.suspended_disclaimer_full': '이 사용자는 중재자에 의해 정지되었습니다.',
  'account.view_full_profile': '전체 프로필 보기',
  'account_note.cancel': '취소',
  'account_note.edit': '편집',
  'account_note.glitch_placeholder': '코멘트가 없습니다',
  'account_note.save': '저장',
  'advanced_options.icon_title': '고급 옵션',
  'advanced_options.local-only.long': '다른 서버에 게시하지 않기',
  'advanced_options.local-only.short': '로컬 전용',
  'advanced_options.local-only.tooltip': '이 게시물은 로컬 전용입니다',
  'advanced_options.threaded_mode.long': '글을 작성하고 자동으로 답글 열기',
  'advanced_options.threaded_mode.short': '글타래 모드',
  'advanced_options.threaded_mode.tooltip': '글타래 모드 활성화됨',
  'boost_modal.missing_description': '이 게시물은 설명이 없는 미디어를 포함하고 있습니다',
  'column.favourited_by': '즐겨찾기 한 사람',
  'column.heading': '기타',
  'column.reblogged_by': '부스트 한 사람',
  'column.subheading': '다양한 옵션',
  'column.toot': '게시물과 답글',
  'column_header.profile': '프로필',
  'column_subheading.lists': '리스트',
  'column_subheading.navigation': '탐색',
  'community.column_settings.allow_local_only': '로컬 전용 글 보기',
  'compose.attach': '첨부…',
  'compose.attach.doodle': '뭔가 그려보세요',
  'compose.attach.upload': '파일 업로드',
  'compose.content-type.html': 'HTML',
  'compose.content-type.markdown': '마크다운',
  'compose.content-type.plain': '일반 텍스트',
  'compose_form.poll.multiple_choices': '여러 개 선택 가능',
  'compose_form.poll.single_choice': '하나만 선택 가능',
  'compose_form.spoiler': '경고 메시지로 숨기기',
  'confirmation_modal.do_not_ask_again': '다음부터 확인창을 띄우지 않기',
  'confirmations.discard_edit_media.message': '저장하지 않은 미디어 설명이나 미리보기가 있습니다, 그냥 닫을까요?',
  'confirmations.missing_media_description.confirm': '그냥 보내기',
  'confirmations.missing_media_description.edit': '미디어 편집',
  'confirmations.missing_media_description.message': '하나 이상의 미디어에 대해 설명을 작성하지 않았습니다. 시각장애인을 위해 모든 미디어에 설명을 추가하는 것을 고려해주세요.',
  'confirmations.unfilter': '이 필터링 된 글에 대한 정보',
  'confirmations.unfilter.author': '작성자',
  'confirmations.unfilter.confirm': '보기',
  'confirmations.unfilter.edit_filter': '필터 편집',
  'confirmations.unfilter.filters': '적용된 {count, plural, one {필터} other {필터들}}',
  'content-type.change': '콘텐트 타입',
  'direct.conversations_mode': '대화',
  'direct.timeline_mode': '타임라인',
  'endorsed_accounts_editor.endorsed_accounts': '추천하는 계정들',
  'favourite_modal.combo': '다음엔 {combo}를 눌러 건너뛸 수 있습니다',
  'getting_started.onboarding': '둘러보기',
  'getting_started.open_source_notice': '글리치는 {Mastodon}의 자유 오픈소스 포크버전입니다. {github}에서 문제를 리포팅 하거나 기여를 할 수 있습니다.',
  'home.column_settings.advanced': '고급',
  'home.column_settings.filter_regex': '정규표현식으로 필터',
  'home.column_settings.show_direct': 'DM 보여주기',
  'home.settings': '컬럼 설정',
  'keyboard_shortcuts.bookmark': '북마크',
  'keyboard_shortcuts.secondary_toot': '보조 프라이버시 설정으로 글 보내기',
  'keyboard_shortcuts.toggle_collapse': '글 접거나 펼치기',
  'layout.auto': '자동',
  'layout.current_is': '현재 레이아웃:',
  'layout.desktop': '데스크탑',
  'layout.hint.auto': '“고급 웹 인터페이스 활성화” 설정과 화면 크기에 따라 자동으로 레이아웃을 고릅니다.',
  'layout.hint.desktop': '“고급 웹 인터페이스 활성화” 설정이나 화면 크기에 관계 없이 멀티 컬럼 레이아웃을 사용합니다.',
  'layout.hint.single': '“고급 웹 인터페이스 활성화” 설정이나 화면 크기에 관계 없이 싱글 컬럼 레이아웃을 사용합니다.',
  'layout.single': '모바일',
  'media_gallery.sensitive': '민감함',
  'moved_to_warning': '이 계정은 {moved_to_link}로 이동한 것으로 표시되었고, 새 팔로우를 받지 않는 것 같습니다.',
  'navigation_bar.app_settings': '앱 설정',
  'navigation_bar.featured_users': '추천된 계정들',
  'navigation_bar.misc': '다양한 옵션들',
  'notification.markForDeletion': '삭제하기 위해 표시',
  'notification_purge.btn_all': '전체선택',
  'notification_purge.btn_apply': '선택된 알림 삭제',
  'notification_purge.btn_invert': '선택반전',
  'notification_purge.btn_none': '전체선택해제',
  'notification_purge.start': '알림 삭제모드로 들어가기',
  'notifications.clear': '내 알림 모두 지우기',
  'notifications.marked_clear': '선택된 알림 모두 삭제',
  'notifications.marked_clear_confirmation': '정말로 선택된 알림들을 영구적으로 삭제할까요?',
  'onboarding.done': '완료',
  'onboarding.next': '다음',
  'onboarding.page_five.public_timelines': '로컬 타임라인은 {domain}에 있는 모든 사람의 공개글을 보여줍니다. 연합 타임라인은 {domain}에 있는 사람들이 팔로우 하는 모든 사람의 공개글을 보여줍니다. 이것들은 공개 타임라인이라고 불리며, 새로운 사람들을 발견할 수 있는 좋은 방법입니다.',
  'onboarding.page_four.home': '홈 타임라인은 당신이 팔로우 한 사람들의 글을 보여줍니다.',
  'onboarding.page_four.notifications': '알림 컬럼은 누군가가 당신과 상호작용한 것들을 보여줍니다.',
  'onboarding.page_one.federation': '{domain}은 마스토돈의 \'인스턴스\'입니다. 마스토돈은 하나의 거대한 소셜 네트워크를 만들기 위해 참여한 서버들의 네트워크입니다. 우린 이 서버들을 인스턴스라고 부릅니다.',
  'onboarding.page_one.handle': '당신은 {domain}에 속해 있으며, 전체 핸들은 {handle} 입니다.',
  'onboarding.page_one.welcome': '{domain}에 오신 것을 환영합니다!',
  'onboarding.page_six.admin': '우리 서버의 관리자는 {admin} 님입니다.',
  'onboarding.page_six.almost_done': '거의 다 되었습니다…',
  'onboarding.page_six.appetoot': '본 아페툿!',
  'onboarding.page_six.apps_available': 'iOS, 안드로이드, 그리고 다른 플랫폼들을 위한 {apps}이 존재합니다.',
  'onboarding.page_six.github': '{domain}은 글리치를 통해 구동 됩니다. 글리치는 {Mastodon}의 {fork}입니다, 그리고 어떤 마스토돈 인스턴스나 앱과도 호환 됩니다. 글리치는 완전한 자유 오픈소스입니다. {github}에서 버그를 리포팅 하거나, 기능을 제안하거나, 코드를 기여할 수 있습니다.',
  'onboarding.page_six.guidelines': '커뮤니티 가이드라인',
  'onboarding.page_six.read_guidelines': '{domain}의 {guidelines}을 읽어주세요!',
  'onboarding.page_six.various_app': '모바일 앱',
  'onboarding.page_three.profile': '프로필을 수정해 아바타, 바이오, 표시되는 이름을 설정하세요. 거기에서 다른 설정들도 찾을 수 있습니다.',
  'onboarding.page_three.search': '검색창을 사용해 사람들과 해시태그를 찾아보세요. 예를 들면 {illustration}이라든지 {introcustions} 같은 것으로요. 이 인스턴스에 있지 않은 사람을 찾으려면, 전체 핸들을 사용하세요.',
  'onboarding.page_two.compose': '작성 컬럼에서 게시물을 작성하세요. 그림을 업로드 할 수 있고, 공개설정을 바꿀 수도 있으며, 아래 아이콘을 통해 열람주의 텍스트를 설정할 수 있습니다.',
  'onboarding.skip': '건너뛰기',
  'settings.always_show_spoilers_field': '열람주의 항목을 언제나 활성화',
  'settings.auto_collapse': '자동으로 접기',
  'settings.auto_collapse_all': '모두',
  'settings.auto_collapse_lengthy': '긴 글',
  'settings.auto_collapse_media': '미디어 포함 글',
  'settings.auto_collapse_notifications': '알림',
  'settings.auto_collapse_reblogs': '부스트',
  'settings.auto_collapse_replies': '답글',
  'settings.close': '닫기',
  'settings.collapsed_statuses': '접힌 글',
  'settings.compose_box_opts': '작성 상자',
  'settings.confirm_before_clearing_draft': '작성 중인 메시지를 덮어씌우기 전에 확인창을 보여주기',
  'settings.confirm_boost_missing_media_description': '미디어 설명이 없는 글을 부스트하려 할 때 확인창을 보여주기',
  'settings.confirm_missing_media_description': '미디어 설명이 없는 글을 작성하려 할 때 확인창을 보여주기',
  'settings.content_warnings': '열람주의',
  'settings.content_warnings.regexp': '정규표현식',
  'settings.content_warnings_filter': '자동으로 펼치지 않을 열람주의 문구:',
  'settings.deprecated_setting': '이 설정은 마스토돈의 {settings_page_link}에서 관리됩니다',
  'settings.enable_collapsed': '접힌 글 활성화',
  'settings.enable_content_warnings_auto_unfold': '자동으로 열람주의 펼치기',
  'settings.filtering_behavior': '필터링 동작',
  'settings.filtering_behavior.cw': '게시물을 보여주되, 필터된 단어를 열람주의에 추가합니다',
  'settings.filtering_behavior.drop': '완전히 숨깁니다',
  'settings.filtering_behavior.hide': '\'필터됨\'이라고 표시하고 이유를 표시하는 버튼을 추가합니다',
  'settings.filtering_behavior.upstream': '\'필터됨\'이라고 일반 마스토돈처럼 표시합니다',
  'settings.filters': '필터',
  'settings.general': '일반',
  'settings.hicolor_privacy_icons': '높은 채도의 공개설정 아이콘',
  'settings.hicolor_privacy_icons.hint': '공개설정 아이콘들을 밝고 구분하기 쉬운 색으로 표시합니다',
  'settings.image_backgrounds': '이미지 배경',
  'settings.image_backgrounds_media': '접힌 글의 미디어 미리보기',
  'settings.image_backgrounds_users': '접힌 글에 이미지 배경 주기',
  'settings.inline_preview_cards': '외부 링크에 대한 미리보기 카드를 같이 표시',
  'settings.layout': '레이아웃:',
  'settings.layout_opts': '레이아웃 옵션',
  'settings.media': '미디어',
  'settings.media_fullwidth': '최대폭 미디어 미리보기',
  'settings.media_letterbox': '레터박스 미디어',
  'settings.media_letterbox_hint': '확대하고 자르는 대신 축소하고 레터박스에 넣어 이미지를 보여줍니다',
  'settings.media_reveal_behind_cw': '열람주의로 가려진 미디어를 기본으로 펼쳐 둡니다',
  'settings.navbar_under': '내비바를 하단에 (모바일 전용)',
  'settings.notifications.favicon_badge': '읽지 않은 알림 파비콘 배지',
  'settings.notifications.favicon_badge.hint': '읽지 않은 알림 배지를 파비콘에 추가합니다',
  'settings.notifications.tab_badge': '읽지 않은 알림 배지',
  'settings.notifications.tab_badge.hint': '알림 컬럼이 열려 있지 않을 때 알림 컬럼에 알림이 있다는 배지를 표시합니다',
  'settings.notifications_opts': '알림 옵션',
  'settings.pop_in_left': '왼쪽',
  'settings.pop_in_player': '떠있는 재생기 활성화',
  'settings.pop_in_position': '떠있는 재생기 위치:',
  'settings.pop_in_right': '오른쪽',
  'settings.preferences': '사용자 설정',
  'settings.prepend_cw_re': '열람주의가 달린 글에 답장을 할 때 열람주의 문구 앞에 “re: ”를 추가합니다',
  'settings.preselect_on_reply': '답글 달 때 사용자명 미리 선택',
  'settings.preselect_on_reply_hint': '답글을 달 때 이미 멘션 된 사람의 사용자명을 미리 블럭으로 설정해 놓습니다',
  'settings.rewrite_mentions': '표시되는 게시물의 멘션 표시 바꾸기',
  'settings.rewrite_mentions_acct': '사용자명과 도메인으로 바꾸기(계정이 원격일 때)',
  'settings.rewrite_mentions_no': '멘션을 그대로 두기',
  'settings.rewrite_mentions_username': '사용자명으로 바꾸기',
  'settings.shared_settings_link': '사용자 설정',
  'settings.show_action_bar': '접힌 글에 액션 버튼들 보이기',
  'settings.show_content_type_choice': '글을 작성할 때 콘텐트 타입을 고를 수 있도록 합니다',
  'settings.show_reply_counter': '대략적인 답글 개수를 표시합니다',
  'settings.side_arm': '보조 작성 버튼:',
  'settings.side_arm.none': '없음',
  'settings.side_arm_reply_mode': '답글을 작성할 때:',
  'settings.side_arm_reply_mode.copy': '답글을 달려는 글의 공개설정을 복사합니다',
  'settings.side_arm_reply_mode.keep': '보조 작성 버튼의 공개설정을 유지합니다',
  'settings.side_arm_reply_mode.restrict': '답글을 달려는 글의 공개설정에 맞게 제한합니다',
  'settings.status_icons': '게시물 아이콘',
  'settings.status_icons_language': '언어 표시',
  'settings.status_icons_local_only': '로컬 전용 표시',
  'settings.status_icons_media': '미디어와 투표 표시',
  'settings.status_icons_reply': '답글 표시',
  'settings.status_icons_visibility': '툿 공개설정 표시',
  'settings.swipe_to_change_columns': '스와이프하여 컬럼간 전환을 허용합니다 (모바일 전용)',
  'settings.tag_misleading_links': '오해의 소지가 있는 링크를 표시합니다',
  'settings.tag_misleading_links.hint': '링크에 명시적으로 주소가 없는 경우엔 대상 호스트를 보이도록 표시합니다',
  'settings.wide_view': '넓은 뷰 (데스크탑 모드 전용)',
  'settings.wide_view_hint': '컬럼들을 늘려서 활용 가능한 공간을 사용합니다.',
  'status.collapse': '접기',
  'status.has_audio': '소리 파일이 첨부되어 있습니다',
  'status.has_pictures': '그림 파일이 첨부되어 있습니다',
  'status.has_preview_card': '미리보기 카드가 첨부되어 있습니다',
  'status.has_video': '영상이 첨부되어 있습니다',
  'status.hide': '글 가리기',
  'status.in_reply_to': '이 글은 답글입니다',
  'status.is_poll': '이 글은 설문입니다',
  'status.local_only': '당신의 서버에서만 보입니다',
  'status.sensitive_toggle': '클릭해서 보기',
  'status.show_filter_reason': '(이유 보기)',
  'status.uncollapse': '펼치기',
  'upload_modal.applying': '적용중…',
  'web_app_crash.change_your_settings': '{settings}을 바꾸세요',
  'web_app_crash.content': '이것들을 시도해 볼 수 있습니다:',
  'web_app_crash.debug_info': '디버그 정보',
  'web_app_crash.disable_addons': '브라우저 애드온이나 기본 번역 도구를 비활성화 합니다',
  'web_app_crash.issue_tracker': '이슈 트래커',
  'web_app_crash.reload': '새로고침',
  'web_app_crash.reload_page': '이 페이지를 {reload}',
  'web_app_crash.report_issue': '{issuetracker}에 버그 제보',
  'web_app_crash.settings': '설정',
  'web_app_crash.title': '죄송합니다, 하지만 마스토돈 앱이 뭔가 잘못되었습니다.',
};

export default Object.assign({}, inherited, messages);
